<!--原材料批检设置-->
<template>
    <div class="container">
        <div class="batch-check-grade-set">
            <div class="wrapper-box">
                <div class="invoiceInfo">
                    <table
                        class="check-set-tb"
                    >
                        <tr>
                            <th>批检编号：</th>
                            <td><el-input :disabled="disable" v-model="testing.no" readonly></el-input></td>
                            <th>原材料分类：</th>
                            <td><el-input :disabled="disable" v-model="testing.type_name"></el-input></td>
                            <th>原材料子类：</th>
                            <td><el-input :disabled="disable" v-model="testing.child_type_name"></el-input></td>
                            <th>原材料规格：</th>
                            <td><el-input :disabled="disable" v-model="testing.spec_name"></el-input></td>
                        </tr>
                        <tr>
                            <th colspan="8" class="tr_border_th">
                                <div class="tr_border"></div>
                            </th>
                        </tr>
                        <tr>
                            <th>供应商：</th>
                            <td><el-input :disabled="disable" v-model="testing.supplier_name"></el-input></td>
                            <th>货源地：</th>
                            <td><el-input :disabled="disable" v-model="testing.source_name"></el-input></td>
                            <th>批检时间：</th>
                            <td>
                                <el-date-picker
                                    v-model="testing.check_date"
                                    type="datetime"
                                    placeholder="选择日期"
                                >
                                </el-date-picker>
                            </td>
                            <th>批检结果：</th>
                            <td>
                                <el-radio-group v-model="testing.result" :disabled="disable||isEnabledStandard">
                                    <el-radio :label="1">
                                        合格
                                    </el-radio>
                                    <el-radio :label="2">
                                        不合格
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="8" class="tr_border_th">
                                <div class="tr_border"></div>
                            </th>
                        </tr>
                        <tr>
                            <th>备注：</th>
                            <td colspan="7">
                                <el-input :disabled="disable" v-model="testing.remarks" placeholder="请输入"></el-input>
                            </td>
                        </tr>
                    </table>
                </div>
                <!--        指标设置-->
                <div class="table-bg">
                    <div class="head_title">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">快检项目</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <el-table
                        :data="details"
                        style="width: 100%"
                        class="table-list"
                        height="100%"
                    >
                        <el-table-column
                            prop="item_name"
                            label="检测项目"
                        >
                            <template slot-scope="scope">
                                <i style="color: red" v-if="scope.row.is_must_check">* </i>{{ scope.row.item_name }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="standard_index"
                            label="合格指标"
                            class-name="notice-cell"
                        >
                            <template slot-scope="scope">
                                {{ getItemStr(scope.row.standard_index,scope.row.unit,scope.row.index_type) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="not_standard_index"
                            label="不合格指标"
                        >
                            <template slot-scope="scope">
                                {{ getItemStr(scope.row.not_standard_index,scope.row.unit,scope.row.index_type) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="actual_value"
                            label="实际指标"
                        >
                            <template slot-scope="scope">
                                <el-input
                                    v-if="scope.row.index_type==0"
                                    v-model="scope.row.actual_value"
                                    placeholder="请输入"
                                    @input="setResult(scope.row)"
                                    :disabled="disable"
                                >
                                    <template slot="suffix">
                                        {{ scope.row.unit }}
                                    </template>
                                </el-input>
                                <el-select
                                    v-if="scope.row.index_type==1"
                                    v-model="scope.row.actual_value"
                                    @change="setResult(scope.row)"
                                    :disabled="disable"
                                >
                                    <el-option
                                        v-if="scope.row.standard_index"
                                        :label="scope.row.standard_index+scope.row.unit"
                                        :value="scope.row.standard_index"
                                    ></el-option>
                                    <el-option
                                        v-if="scope.row.compromise_index"
                                        :label="scope.row.compromise_index+scope.row.unit"
                                        :value="scope.row.compromise_index"
                                    ></el-option>
                                    <el-option
                                        v-if="scope.row.not_standard_index"
                                        :label="scope.row.not_standard_index+scope.row.unit"
                                        :value="scope.row.not_standard_index"
                                    ></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="result"
                            label="检测结果"
                        >
                            <template slot-scope="scope">
                                <span :style="{...getResultText(scope.row.result).style, width: '.7rem'}">
                                    {{ getResultText(scope.row.result).txt }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 照片信息 -->
                <div class="check-wrapper">
                    <!-- <div class="title">
                        添加照片
                    </div> -->
                    <div class="head_title">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">添加照片</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <div class="img-list">
                        <el-upload
                            action=""
                            list-type="picture-card"
                            accept="image/*"
                            :on-preview="handlePictureCardPreview"
                            :before-remove="handleRemove"
                            :auto-upload="false"
                            :file-list="fileList"
                            :on-change="onChangeFile"
                            :limit="10"
                            multiple
                            :disabled="operatetype==2"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                </div>
                <!-- <div class="check-wrapper">
                    <div class="title">
                        视频信息
                    </div>
                    <div class="img-list">
                        <template v-for="(file,index) in videoList">
                            <video
                                :key="index"
                                class="el-upload-list__item-thumbnail"
                                :src="file.url"
                                @click="handleVideoPreview(file)"
                            ></video>
                        </template>
                    </div>
                </div> -->
            </div>
            <div class="btns">
                <el-button
                    class="save"
                    type="primary"
                    @click="saveData"
                    v-if="!disable"
                >
                    保存
                </el-button>
                <el-button class="close" @click="handleClose">
                    关闭
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'batch-check-grade-set',
    data() {
        return {
            buCode: 'ProductionAffix',
            aliyunOssToken: null,
            mbtid: '',
            // 基本信息
            testing: {},
            // 检测项目列表
            details: [],
            disable: false,

            // 操作类型 1编辑 2查看
            operatetype: 1,

            // 是否启用标准
            isEnabledStandard: false,

            // 文件列表
            fileList: [],
        };
    },
    props: {
        extr: Object,
    },
    created() {
        this.getOssToken();
        this.init();
    },
    methods: {
        // 上传文件
        async uploadFileMethod() {
            if (this.fileList.length === 0) {
                return;
            }
            // 如果没有文件分组 先创建文件分组
            if (!this.filesGroupId) {
                this.upGroupFun();
            }
            this.$nextTick(() => {
                for (const item of this.fileList) {
                    if (!item.fileId) {
                        // const formData = new FormData();
                        // formData.append('files', item.raw);
                        // formData.append('code', 'ProductionAffix');
                        // formData.append('data_id', this.testing.id);
                        // formData.append('group_id', this.filesGroupId);
                        // const fileId = await this.$axios.post(this.FILE.FILE_BASE_URL() + 'file/uploadfile', formData);
                        // item.fileId = fileId;
                        this.upGroupFun(item);
                    }
                }
            });
        },
        // 获取上传通行证
        getOssToken() {
            this.$axios.get(this.FILE.FILE_BASE_URL_OSS() + `credential/GetPostPolicy/${this.buCode}`).then(res => {
                if (res) {
                    this.aliyunOssToken = res;
                    // localStorage.setItem('aliyunOssToken', JSON.stringify(res));
                }
            });
        },
        // 上传组名
        upGroupFun(val) {
            const filename = val ? val.name : '现场照片';
            // 判断是否过期，过期重新调用获取通信证方法
            const formData = new FormData();
            // 注意formData里append添加的键的大小写
            formData.append('key', `${this.aliyunOssToken.dir}${this.testing.id}/${filename}`); // 存储在oss的文件路径
            formData.append('OSSAccessKeyId', this.aliyunOssToken.accessid); // accessKeyId
            formData.append('policy', this.aliyunOssToken.policy); // policy
            formData.append('Signature', this.aliyunOssToken.signature); // 签名
            // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            formData.append('file', val ? val.raw : filename);
            // formData.append('FileDescription', this.newFile.FileDescription);
            formData.append('success_action_status', 200); // 成功后返回的操作码
            formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码

            // 回调参数
            const obj = {
                bucket: this.aliyunOssToken.bucket ? this.aliyunOssToken.bucket : this.buCode, // 存储桶名称
                business_type_code: this.buCode, // 业务类型编码
                object_name: `${this.aliyunOssToken.dir}${this.testing.id}/${filename}`, // 文件对象名
                show_name: filename, // 显示文件名
                size: 0, // 文件大小
                mimeType: '',
                mime_type: val ? val.raw : '',
                data_id: this.testing.id, // 业务数据id
                directory_id: '', // 目录id
                tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                // FileDescription: this.newFile.FileDescription, // 文件描述
            };
            this.uploadFun(formData, obj);
        },
        // 上传文件
        uploadFun(formData, val) {
            $.ajax({
                url: this.aliyunOssToken.host,
                type: 'POST',
                data: formData,
                cache: false, // 不缓存数据
                processData: false, // 不处理数据
                contentType: false, // 不设置内容类型
                success: data => { // 成功回调
                    this.callBackFun(val);
                },
            });
        },
        // 上传的回调
        callBackFun(obj) {
            this.$axios.post(this.FILE.FILE_BASE_URL_OSS() + 'api/UploadCallback', obj).then(res => {
                if (res) {
                    console.log('上传成功');
                }
            });
        },
        // 获取文件列表
        async getFileList() {
            if (this.testing.id) {
                // 1.先获取所有文件夹和文件
                try {
                    const url = this.FILE.FILE_BASE_URL_OSS() + `api/GetFileList/${this.testing.id}`;
                    const fileDataArr = await this.$axios.get(url);
                    fileDataArr.forEach(item => {
                        if (item.show_name === '现场照片') {
                            this.filesGroupId = item.id;
                        } else {
                            this.fileList.push({
                                fileId: item.id,
                                url: '',
                            });
                        }
                    });
                    this.$nextTick(() => {
                        this.getFileDetailFun(this.fileList);
                    });
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        },
        // 获取文件下载预览地址
        getFileDetailFun(data) {
            const arr = data;
            arr.forEach(item => {
                this.$axios
                    .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + item.fileId)
                    .then(res => {
                        item.url = res.preview_url;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            });
            this.fileData = arr;
        },
        // 预览
        handlePictureCardPreview(file) {
            let url = '';
            if (file.fileId) {
                url = file.url;
            } else {
                url = URL.createObjectURL(file.raw);
            }
            this.previewImg([url]);
        },
        // 预览图片
        previewImg(urls) {
            this.$toast({
                title: true,
                text: '图片预览',
                type: 'eject',
                width: '15.4rem',
                t_url: 'picturePreview/index',
                extr: {
                    code: {
                        imgSrc: urls,
                    },
                    isImg: true,
                },
                viewPosition: 'components',
            });
        },
        // 文件上传到本地缓存
        onChangeFile(file, fileList) {
            this.fileList = fileList;
        },
        // 文件删除
        handleRemove(file) {
            let delIndex = null;
            const _that = this;
            for (const index in this.fileList) {
                if (file === this.fileList[index]) {
                    delIndex = index;
                    break;
                }
            }
            if (delIndex !== null) {
                this.fileList.splice(delIndex, 1);
                if (file.fileId) {
                    this.$axios
                        .delete(this.FILE.FILE_BASE_URL_OSS() + 'api/DeleteFileInfo/' + file.fileId, {
                        })
                        .then(() => {
                            _that.$message({
                                message: '删除成功！',
                                type: 'success',
                            });
                        })
                        .catch(error => {
                            _that.$message.error(error.ErrorCode.Message);
                        });
                }
            }
        },
        async init() {
            this.mbtid = this.extr.id;
            this.disable = Boolean(this.extr.IsView);
            if (this.disable) {
                this.operatetype = 2;
            }
            this.getTestInfo();
        },
        // 获取批检信息
        getTestInfo() {
            this.$axios.get(`/interfaceApi/procurement/material/batch/testing/${this.mbtid}?operatetype=${this.operatetype}`)
                .then(data => {
                    this.testing = data.testing;
                    this.details = data.details.map(item => {
                        item.result = item.result || 0;
                        item.unit = item.unit || '';
                        return item;
                    });
                    this.getStationStandardEnabled();
                    this.getFileList();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存信息
        saveData() {
            const dto = {
                testing: this.testing,
                details: this.details,
            };
            this.$axios.post('/interfaceApi/procurement/material/batch/testing/save', dto)
                .then(async data => {
                    if (data) {
                        await this.uploadFileMethod();
                        this.$message.success('操作成功');
                        window.updateTable();
                        this.handleClose();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        handleClose() {
            this.$parent.hide();
        },
        // 获取指标字符串
        getItemStr(str) {
            str = str || '';
            return str.replace(/\|/g, '');
        },
        // 设置结果
        setResult(item) {
            // 计算快检结果
            if (item.actual_value === '' || item.actual_value === null) {
                item.result = 0;
            } else if (item.standard_index && this.getExpression(item.standard_index, item.index_type, item.actual_value)) {
                item.result = 1;
            } else {
                item.result = 2;
            }
            // 初始化 合格个数，不合格个数，让步个数
            let standardNum = 0; let noStandardNum = 0;
            // 所有的必检项 是否检测完
            let mustCheckAll = true;

            // 筛选所有:有一个不合格 测试结果为不合格/合格
            for (const o of this.details) {
                if (o.result === 1) {
                    standardNum++;
                } else if (o.result === 2) {
                    noStandardNum++;
                }
                if (o.is_must_check && o.result === 0) {
                    mustCheckAll = false;
                }
            }
            // 最终计算总结果 按低级不合格开始...
            let defaultResult = 0;
            // 必检项全部检测完成 计算最终结果
            if (mustCheckAll) {
                if (noStandardNum > 0) {
                    defaultResult = 2;
                } else if (standardNum > 0) {
                    defaultResult = 1;
                }
            }
            this.testing.result = defaultResult;
        },
        // 生成表达式js index_value指标对象
        /** *
         * 生成表达式js 判断是否符合当前指标
         * @param index_value  指标对象
         * @param index_type   指标类型
         * @param actual_value 当前设置的值
         * @returns {boolean}
         */
        getExpression(index_value, index_type, actual_value) {
            index_value = this.getItemStr(index_value);
            index_value = index_value.replaceAll('=', '==').replaceAll('≥', '>=').replaceAll('≤', '<=');
            let resultJs = true;
            // 指标类型区间
            if (index_type === 0) {
                const val = this.getItemStr(index_value);
                resultJs += '&&' + actual_value + val.replace('并且', '&&' + actual_value).replace('或者', '||' + actual_value);
                // 指标类型 指定值
            } else if (index_type === 1) {
                resultJs += `&&'${index_value}'=='${actual_value}'`;
            }
            // eslint-disable-next-line no-eval
            return eval(resultJs);
        },
        // 检测每项结果文本
        getResultText(result = 0) {
            const obj = {
                0: {
                    txt: '未检测',
                    color: '#979EA7',
                    style: {
                        color: '#979EA7',
                        background: '#fafafa',
                        border: '1px solid #979EA7',
                        padding: '.05rem .1rem',
                    },
                },
                1: {
                    txt: '合格',
                    color: '#00A00E',
                    style: {
                        color: '#00A00E',
                        background: '#F3FDF6',
                        border: '1px solid #00A00E',
                        padding: '.05rem .1rem',
                    },
                },
                2: {
                    txt: '不合格',
                    color: '#FF1500',
                    style: {
                        color: '#FF1500',
                        background: '#FFF6F4',
                        border: '1px solid #FF1500',
                        padding: '.05rem .1rem',
                    },
                },
            };
            return obj[result];
        },
        // 通过场站组织机构ID获取原材料是否启动规范快检
        async getStationStandardEnabled() {
            const url = `/interfaceApi/baseinfo/stationmanger/get_station_standard_batch_quality_enabled/${this.testing.station_id}`;
            const res = await this.$axios.get(url,);
            if (res) {
                this.isEnabledStandard = true;
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
>>> .el-upload--picture-card
    border 1px dashed #006EFF !important
    i
        color #006EFF !important
.container
    width 100%;
    height 100%;
.batch-check-grade-set
    background #ffffff
    position relative;
    width 100%;
    height 100%;
    .wrapper-box
        width 100%;
        height calc(100% - 0.9rem);
        overflow-y auto;
        padding .1rem
        // table
        //     width 100%;
        //     border-spacing: 0;
        //     border-collapse: collapse;
        //     th,td
        //         font-size: 0.16rem;
        //         border: 1px solid #D7D7D7;
        //         padding 0.03rem;
        //     th
        //         padding-left: 0.2rem;
        //         background: #F8F8F8;
        //         text-align right
        //     td
        //         color #333;
        //         >>> .el-radio-group
        //             padding-left 0.2rem;
        //             white-space nowrap
        //             .el-radio
        //                 margin-right 0.15rem
        //         >>> input
        //             border 0;
        //             font-size 0.16rem;
        //         .el-input.is-disabled .el-input__inner
        //             background-color: #fff;
        //             color #333;
        //         >>> .el-input.is-disabled .el-input__inner
        //             background-color: #fff;
        //             color #333;
        //         >>> .el-select
        //             width 100%;
        .invoiceInfo
            border 1px solid #D7D7D7
            table
                width 100%;
                box-sizing border-box
                border-collapse collapse
                th,td
                    border 2px solid #ffffff
                    font-size 0.14rem
                    line-height 0.4rem
                    font-weight 400
                    background #F8F8F8
                    border-bottom none !important
                th
                    width 1.1rem
                    text-align right
                td
                    width 2.4rem
                    color #333;
                    background #fff;
                    padding 0.05rem
                    .readonly_input
                        >>> .el-input__inner
                            background #F8F8F8  !important
                    >>> .el-radio-group
                        white-space nowrap
                        .el-radio
                            margin-right 0.15rem
                    >>> .el-input__inner
                        padding-left .05rem
                    >>> .is-disabled .el-input__inner
                        color #333;
                        background-color #F8F8F8
        .check-item-tb
            td,th
                text-align center
                padding 0.06rem 0;
            >>> .el-radio
                display: block;
                text-align: left;
                padding: 0.03rem;
            >>> .el-input__inner
                text-align center!important;
    .btns
        position absolute
        left 0;
        bottom 0;
        padding-bottom 0.2rem;
        width 100%;
        display flex
        justify-content center;
        >>> .el-button
            width: 2.6rem;
            height: 0.5rem;
            font: 0.2rem '微软雅黑';
            margin: 0 0.15rem;
            border: none;
            cursor: pointer;
        .save
            color: #fff;
            background: #1577d2;
        .close
            color: #1577d2;
            background: #fff;
            border: 1px solid #1577d2;
    .table-bg
        padding 0.06rem;
        height calc(100% - 2.8rem)
        margin-bottom .35rem
        >>> .el-table
            th
                height 0.43rem
                background #F8F8F8
            td
                padding 0.03rem
                height 0.28rem
            .cell
                display flex
                justify-content center
                align-items center
                font-size 0.16rem
                .el-input__inner
                    width 2.22rem
        >>> .el-radio__label
            display inline-block
        >>> .el-input__suffix
            display flex
            align-items center
            color #666
    .check-wrapper
        padding 0.1rem

        .title
            position relative
            display flex
            align-items center
    .img-list
        width 100%;
        padding 0.1rem 0
        border-top 1px solid #D7D7D7
        video
            width 0.78rem
            height 0.78rem
            object-fit: cover;
            border-radius 0.05rem
            cursor pointer

        >>> .el-upload-list__item, >>> .el-upload
            width 0.8rem
            height 0.8rem
            line-height 0.8rem;
            text-align center

        >>> .el-upload-list__item-status-label
            display none
        .el-upload-list__item-thumbnail
            object-fit cover
        .el-upload-list__item-actions span
            margin-left 0.05rem
</style>
